$(document).ready(function(){
	if (cn_are_cookies_enabled() && !cn_getCookie('cookie_notify_policy_accepted')) {
		$('.cookieconsent_wrapper').delay(1300).fadeIn('slow');

		$(".cookieconsent_wrapper .btn-cookie-accept").click(function(e){
			e.preventDefault();
			$(".cookieconsent_wrapper").stop(true, true).fadeOut(function(){
				$(this).remove();
				cn_setCookie('cookie_notify_policy_accepted', 1, 365);
			});
		});
	}
});

function cn_are_cookies_enabled()
{
	var cookieEnabled = (navigator.cookieEnabled) ? true : false;
	if (typeof navigator.cookieEnabled == "undefined" && !cookieEnabled)
	{
		document.cookie="testcookie";
		cookieEnabled = (document.cookie.indexOf("testcookie") != -1) ? true : false;
	}
	return (cookieEnabled);
}

function cn_setCookie(c_name,value,exdays)
{
	var exdate=new Date();
	exdate.setDate(exdate.getDate() + exdays);
	var c_value=escape(value) + ((exdays==null) ? "" : "; expires="+exdate.toUTCString());
	document.cookie=c_name + "=" + c_value + " "+ document.domain + "; path=/;";
}

function cn_getCookie(c_name)
{
	var i,x,y,ARRcookies=document.cookie.split(";");
	for (i=0;i<ARRcookies.length;i++)
	{
		x=ARRcookies[i].substr(0,ARRcookies[i].indexOf("="));
		y=ARRcookies[i].substr(ARRcookies[i].indexOf("=")+1);
		x=x.replace(/^\s+|\s+jQuery/g,"");
		if (x==c_name)
		{
			return unescape(y);
		}
	}
}